import React from "react"
import styles from "./Skills.module.scss"
import Title from "../../components/Title/Title"
import Heading from "../../components/Heading/Heading"
import Button from "../../components/Button/Button"
import Paragraph from "../../components/Paragraph/Paragraph"
import { StaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import Reveal from "react-reveal/Reveal"

const Skills = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <section
      className={` ${styles.skillsSection} ${"section--bg"}`}
      id="skills"
    >
      <Title type="sectionTitle" className={styles.sectionTitle}>
        {frontmatter.title}
      </Title>
      <div className={styles.sectionWrap}>
        <Reveal effect="left-to-right" duration="500">
          <div className={styles.sectionLeft}>
            <Reveal effect="left-to-right" duration="500" delay="250">
              <div className={styles.linkedInBlock}>
                <Reveal delay="750" effect="fade-bottom" duration="750">
                  <Heading className={styles.linkedInHeading}>
                    {frontmatter.subheading}
                  </Heading>
                </Reveal>

                <Fade delay="750" duration="500">
                  <Button
                    className={styles.linkedInButton}
                    href={frontmatter.linkedInLink}
                    target="_blank"
                  >
                    Add me on LinkedIn
                  </Button>
                </Fade>
              </div>
            </Reveal>
          </div>
        </Reveal>
        <div className={styles.sectionRight}>
          <Reveal effect="fade-bottom" duration="750">
            <Heading
              className={styles.skillsHeading}
              dangerouslySetInnerHTML={{ __html: frontmatter.heading }}
            />
          </Reveal>

          <Reveal delay="600" effect="fade-bottom" duration="750">
            <Paragraph className={styles.skillsParagraph}>
              {frontmatter.description}
            </Paragraph>
          </Reveal>
        </div>
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "skills" } }) {
          frontmatter {
            title
            heading
            subheading
            description
            linkedInLink
          }
        }
      }
    `}
    render={data => <Skills data={data} {...props} />}
  />
)
