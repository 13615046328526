import React from "react"
import styles from "./Companies.module.scss"
import { StaticQuery, graphql } from "gatsby"

const Companies = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  console.log(frontmatter)

  return (
    <section className={styles.section}>
      <h2 className={styles.heading}>The companies and brands I worked with</h2>
      <div className={styles.imageContainer}>
        {frontmatter.images.map((item, i) => (
          <img src={item.image} className={styles.image} alt="img" />
        ))}
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "companies" } }) {
          frontmatter {
            images {
              image
            }
          }
        }
      }
    `}
    render={data => <Companies data={data} {...props} />}
  />
)
